import React from 'react';
import OrderHistory from '../../Components/Order/OrderHistory';

const History = () => {
  return (
    <>
      <OrderHistory/>
    </>
  )
}

export default History;
