import React from 'react';
import Cart from '../../Components/Cart/Cart';

const Checkout = () => {
  return (
    <>
      <Cart/>
    </>
  )
}

export default Checkout;
